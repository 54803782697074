// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /* custom spacing */
  --ion-page-default-padding: 12px;
  --ion-page-mobile-padding: 24px;
  --app-custom-modal-default-padding: 18px;
  /* custom spacing */

  /* custom primary */
  --om-primary-base: #2AB3D8;

  /* custom greyscale */
  --om-grey-primary: #181818;
  --om-grey-secondary: #747474;
  --om-grey-secondary-light: #737373;
  --om-grey-tertiary: #989898;
  --om-grey-stroke: #CCCCCC;
  --om-grey-dividers: #EDEDED;
  --om-grey-light: #f8f8f8;
  --om-grey-white: #FFFFFF;

  /* custom brand colors */
  --om-brand-800: #151551;
  --om-brand-700: #1F1F7A;
  --om-brand-600: #2A2AA2;
  --om-brand-500: #2B2BA6;
  --om-brand-400: #5D5DD5;
  --om-brand-300: #8585E0;
  --om-brand-200: #AEAEEA;
  --om-brand-100: #D6D6F5;
  --om-brand-50: #EBEBFA;
  --om-brand-30: #F8F8FF;


  /* custom system colors */
  --om-successful-hover: #21A40C;
  --om-successful: #3AC922;
  --om-successful-secondary: #B2FFA6;
  --om-successful-tertiary: #E6FFE2;
  --om-attention-hover: #E8C71E;
  --om-attention: #F7D523;
  --om-attention-secondary: #FFEF98;
  --om-attention-tertiary: #FFFAE1;
  --om-error-hover: #DA1111;
  --om-error: #FF3030;
  --om-error-secondary: #FFC0C0;
  --om-error-tertiary: #FFF2F2;
  --om-accent-primary: #7c2c8c;

  --om-module-primary-color: var(--om-brand-500);
  --om-module-secondary-color: var(--om-brand-300);

  --ion-page-default-background: var(--om-grey-light);

  --progress-custom-background-color: var(--om-module-primary-color);

  /* Modulos Default Colors */
  --om-mama-default-color: #FF81C5;
  --om-pulmao-nsclc-default-color: #325260;
  --om-pulmao-sclc-default-color: #325260;
  --om-prostata-default-color: #3E83EB;
  --om-bexiga-default-color: #6f42de;
  --om-colorretal-default-color: #110a88;
  --om-rim-default-color: #e12525;
  /* Modulos Default Colors */

  /* Modulos Secondary Colors */
  --om-mama-secondary-color: #fceff6;
  --om-pulmao-nsclc-secondary-color: #edf8ff;
  --om-pulmao-sclc-secondary-color: #e0f9ff;
  --om-prostata-secondary-color: #c1dbfd;
  --om-bexiga-secondary-color: #e1dbff;
  --om-colorretal-secondary-color: #ececff;
  --om-rim-secondary-color: #ffc4c4;
  /* Modulos Secondary Colors */

  /* custom box shadow */
  --om-button-bs: 0px 3px 5px rgba(41, 52, 208, 0.15);
  --om-inputs-bs: 0px 3px 5px rgba(0, 0, 0, 0.05);

  --om-card-paciente-bs: 0px 3px 5px rgba(0, 0, 0, 0.05);
  --om-card-configuracoes-bs: 0px 2px 16px rgba(75, 75, 75, 0.15);
}
