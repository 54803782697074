/*
O arquivo `src/utilities.css` não é importado diretamente pelo app;
ele é processado pelo Tailwind através do comando `yarn build:utilities`,
que então gera o arquivo final `build/utilities.css`.

Para configurar as classes do Tailwind, edite o arquivo `tailwind.config.js`.
Mais informações sobre as diretivas do Tailwind podem ser vistas em:
https://tailwindcss.com/docs/functions-and-directives

Classes não usadas são omitidas nas builds de produção. Para que isso funcione
corretamente, as classes utilitárias nunca podem ser geradas dinamicamente,
precisam sempre ter seu nome escrito por inteiro.

Por exemplo, ao invés de escrever:
    class="text-{{grande ? 'lg' : 'sm'}}"
Use:
    class="{{grande ? 'text-lg' : 'text-sm'}}"
*/

@tailwind utilities;

@responsive {
  /* safe area */
  .pl-safe, .px-safe, .p-safe { padding-left: var(--ion-safe-area-left); }
  .pr-safe, .px-safe, .p-safe { padding-right: var(--ion-safe-area-right); }
  .pt-safe, .py-safe, .p-safe { padding-top: var(--ion-safe-area-top); }
  .pb-safe, .py-safe, .p-safe { padding-bottom: var(--ion-safe-area-bottom); }
  .ml-safe, .mx-safe, .m-safe { margin-left: var(--ion-safe-area-left); }
  .mr-safe, .mx-safe, .m-safe { margin-right: var(--ion-safe-area-right); }
  .mt-safe, .my-safe, .m-safe { margin-top: var(--ion-safe-area-top); }
  .mb-safe, .my-safe, .m-safe { margin-bottom: var(--ion-safe-area-bottom); }

  /* padding + safe area */
  .pl-4\+safe, .px-4\+safe, .p-4\+safe { padding-left: calc(1rem + var(--ion-safe-area-left)); }
  .pr-4\+safe, .px-4\+safe, .p-4\+safe { padding-right: calc(1rem + var(--ion-safe-area-right)); }
  .pt-4\+safe, .py-4\+safe, .p-4\+safe { padding-top: calc(1rem + var(--ion-safe-area-top)); }
  .pb-4\+safe, .py-4\+safe, .p-4\+safe { padding-bottom: calc(1rem + var(--ion-safe-area-bottom)); }
  .ml-4\+safe, .mx-4\+safe, .m-4\+safe { margin-left: calc(1rem + var(--ion-safe-area-left)); }
  .mr-4\+safe, .mx-4\+safe, .m-4\+safe { margin-right: calc(1rem + var(--ion-safe-area-right)); }
  .mt-4\+safe, .my-4\+safe, .m-4\+safe { margin-top: calc(1rem + var(--ion-safe-area-top)); }
  .mb-4\+safe, .my-4\+safe, .m-4\+safe { margin-bottom: calc(1rem + var(--ion-safe-area-bottom)); }
  .pl-5\+safe, .px-5\+safe, .p-5\+safe { padding-left: calc(1.25rem + var(--ion-safe-area-left)); }
  .pr-5\+safe, .px-5\+safe, .p-5\+safe { padding-right: calc(1.25rem + var(--ion-safe-area-right)); }
  .pt-5\+safe, .py-5\+safe, .p-5\+safe { padding-top: calc(1.25rem + var(--ion-safe-area-top)); }
  .pb-5\+safe, .py-5\+safe, .p-5\+safe { padding-bottom: calc(1.25rem + var(--ion-safe-area-bottom)); }
  .ml-5\+safe, .mx-5\+safe, .m-5\+safe { margin-left: calc(1.25rem + var(--ion-safe-area-left)); }
  .mr-5\+safe, .mx-5\+safe, .m-5\+safe { margin-right: calc(1.25rem + var(--ion-safe-area-right)); }
  .mt-5\+safe, .my-5\+safe, .m-5\+safe { margin-top: calc(1.25rem + var(--ion-safe-area-top)); }
  .mb-5\+safe, .my-5\+safe, .m-5\+safe { margin-bottom: calc(1.25rem + var(--ion-safe-area-bottom)); }
  .pl-7\+safe, .px-7\+safe, .p-7\+safe { padding-left: calc(1.75rem + var(--ion-safe-area-left)); }
  .pr-7\+safe, .px-7\+safe, .p-7\+safe { padding-right: calc(1.75rem + var(--ion-safe-area-right)); }
  .pt-7\+safe, .py-7\+safe, .p-7\+safe { padding-top: calc(1.75rem + var(--ion-safe-area-top)); }
  .pb-7\+safe, .py-7\+safe, .p-7\+safe { padding-bottom: calc(1.75rem + var(--ion-safe-area-bottom)); }
  .ml-7\+safe, .mx-7\+safe, .m-7\+safe { margin-left: calc(1.75rem + var(--ion-safe-area-left)); }
  .mr-7\+safe, .mx-7\+safe, .m-7\+safe { margin-right: calc(1.75rem + var(--ion-safe-area-right)); }
  .mt-7\+safe, .my-7\+safe, .m-7\+safe { margin-top: calc(1.75rem + var(--ion-safe-area-top)); }
  .mb-7\+safe, .my-7\+safe, .m-7\+safe { margin-bottom: calc(1.75rem + var(--ion-safe-area-bottom)); }

  /* max(padding, safe area) */
  .pl-5\/safe, .px-5\/safe, .p-5\/safe { padding-left: 1.25rem; }
  .pr-5\/safe, .px-5\/safe, .p-5\/safe { padding-right: 1.25rem; }
  .pt-5\/safe, .py-5\/safe, .p-5\/safe { padding-top: 1.25rem; }
  .pb-5\/safe, .py-5\/safe, .p-5\/safe { padding-bottom: 1.25rem; }
  .ml-5\/safe, .mx-5\/safe, .m-5\/safe { margin-left: 1.25rem; }
  .mr-5\/safe, .mx-5\/safe, .m-5\/safe { margin-right: 1.25rem; }
  .mt-5\/safe, .my-5\/safe, .m-5\/safe { margin-top: 1.25rem; }
  .mb-5\/safe, .my-5\/safe, .m-5\/safe { margin-bottom: 1.25rem; }
  .pl-7\/safe, .px-7\/safe, .p-7\/safe { padding-left: 1.75rem; }
  .pr-7\/safe, .px-7\/safe, .p-7\/safe { padding-right: 1.75rem; }
  .pt-7\/safe, .py-7\/safe, .p-7\/safe { padding-top: 1.75rem; }
  .pb-7\/safe, .py-7\/safe, .p-7\/safe { padding-bottom: 1.75rem; }
  .ml-7\/safe, .mx-7\/safe, .m-7\/safe { margin-left: 1.75rem; }
  .mr-7\/safe, .mx-7\/safe, .m-7\/safe { margin-right: 1.75rem; }
  .mt-7\/safe, .my-7\/safe, .m-7\/safe { margin-top: 1.75rem; }
  .mb-7\/safe, .my-7\/safe, .m-7\/safe { margin-bottom: 1.75rem; }

  /* @supports é necessário porque Chrome 74 acha que suporta `max()`
    quando `var()` também está presente na mesma expressão */
  @supports (top: max(0px)) {
    .pl-5\/safe, .px-5\/safe, .p-5\/safe { padding-left: max(1.25rem, var(--ion-safe-area-left)); }
    .pr-5\/safe, .px-5\/safe, .p-5\/safe { padding-right: max(1.25rem, var(--ion-safe-area-right)); }
    .pt-5\/safe, .py-5\/safe, .p-5\/safe { padding-top: max(1.25rem, var(--ion-safe-area-top)); }
    .pb-5\/safe, .py-5\/safe, .p-5\/safe { padding-bottom: max(1.25rem, var(--ion-safe-area-bottom)); }
    .ml-5\/safe, .mx-5\/safe, .m-5\/safe { margin-left: max(1.25rem, var(--ion-safe-area-left)); }
    .mr-5\/safe, .mx-5\/safe, .m-5\/safe { margin-right: max(1.25rem, var(--ion-safe-area-right)); }
    .mt-5\/safe, .my-5\/safe, .m-5\/safe { margin-top: max(1.25rem, var(--ion-safe-area-top)); }
    .mb-5\/safe, .my-5\/safe, .m-5\/safe { margin-bottom: max(1.25rem, var(--ion-safe-area-bottom)); }
    .pl-7\/safe, .px-7\/safe, .p-7\/safe { padding-left: max(1.75rem, var(--ion-safe-area-left)); }
    .pr-7\/safe, .px-7\/safe, .p-7\/safe { padding-right: max(1.75rem, var(--ion-safe-area-right)); }
    .pt-7\/safe, .py-7\/safe, .p-7\/safe { padding-top: max(1.75rem, var(--ion-safe-area-top)); }
    .pb-7\/safe, .py-7\/safe, .p-7\/safe { padding-bottom: max(1.75rem, var(--ion-safe-area-bottom)); }
    .ml-7\/safe, .mx-7\/safe, .m-7\/safe { margin-left: max(1.75rem, var(--ion-safe-area-left)); }
    .mr-7\/safe, .mx-7\/safe, .m-7\/safe { margin-right: max(1.75rem, var(--ion-safe-area-right)); }
    .mt-7\/safe, .my-7\/safe, .m-7\/safe { margin-top: max(1.75rem, var(--ion-safe-area-top)); }
    .mb-7\/safe, .my-7\/safe, .m-7\/safe { margin-bottom: max(1.75rem, var(--ion-safe-area-bottom)); }
  }
}
