/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Tailwind styles */
@import "~tailwindcss/base.css";
@import "~tailwindcss/components.css";
@import "~tailwindcss/utilities.css";

@import "utilities.css";
@import "src/shared/components/global.component";

/* Swiper styles */
@import '~swiper/swiper-bundle.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400&display=swap');

* {
  font-family: 'Roboto', sans-serif !important;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.loading-oncomax {
  .loading-wrapper {
    display: block;
    text-align: center;
    box-shadow: none;
    background: transparent none !important;

    ion-spinner {
      width: 80px;
      height: 80px;
      background-image: url("assets/svg/oncomax-loading.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    .loading-content {
      font-size: 0.563rem;
      text-transform: capitalize;
      animation: blinker 2s linear infinite;
      color: var(--ion-color-primary);
      margin-left: unset;
      -webkit-margin-start: 0 !important;
      margin-inline-start: 0 !important;
    }
  }

  .spinner-dots {
    &.sc-ion-loading-md {
      color: transparent;
    }

    &.sc-ion-loading-ios {
      color: transparent;
    }
  }

  .loading-content {
    &.sc-ion-loading-ios {
      color: white;
    }
  }
}

.ion-oncomax-default-page {

  ion-content {
    --padding-start: var(--ion-page-default-padding);
    --padding-end: var(--ion-page-default-padding);
    --padding-top: var(--ion-page-default-padding);
    --padding-bottom: 0;
    --background: var(--ion-page-default-background);
  }

  ion-header {

    &:after {
      content: unset;
    }
  }

  ion-footer {
    background: var(--ion-page-default-background);

    &:before {
      content: unset;
    }
  }
}

.ion-oncomax-mobile-page {

  ion-content {
    --padding-start: var(--ion-page-mobile-padding);
    --padding-end: var(--ion-page-mobile-padding);
    --padding-top: var(--ion-page-mobile-padding);
    --padding-bottom: var(--ion-page-mobile-padding);
    --background: var(--ion-page-default-background);
  }

  ion-header {

    &:after {
      content: unset;
    }
  }

  ion-footer {
    background: var(--ion-page-default-background);

    &:before {
      content: unset;
    }
  }
}

ion-tab-bar {
  --background: transparent;
}

ktd-grid {
  .ktd-grid-item-placeholder {
    background-color: transparent !important;
  }
}

.onboarding-slider {
  .swiper-pagination { bottom: -6px }
  .swiper-pagination-bullet {
    background-color: white;
    opacity: .1;

    &.swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
}

app-timeline-vertical-three-d-cube {
  position: relative;
  width: 100px;
  height: auto;
  transform: translateY(28px) translateX(32px);
}

@keyframes custom-oncomax-animation {
  0%{
    transform: scale(1.5) rotate(0deg);
    opacity: 1;
  }
  25%{
    transform: scale(1.75) rotate(90deg);
    opacity: 0.5;
  }
  50%{
    transform: scale(2) rotate(180deg);
    opacity: 0.75;
  }
  75%{
    transform: scale(1.75) rotate(270deg);
    opacity: 0.5;
  }
  100%{
    transform: scale(1.5) rotate(360deg);
    opacity: 1;
  }
}

angular-editor-toolbar {
  button {
    i {
      font-family: FontAwesome !important;
      color: #474d6d !important;
    }
  }
}

.swiper-quiz {
  .swiper-button-next::after{
    color: var(--om-module-primary-color);
  }
  .swiper-button-prev::after{
    color: var(--om-module-primary-color);
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.preenchimento-automatico-ficha {

  .angular-editor {
    height: 100%;

    .angular-editor-wrapper {
      height: calc(100% - 46px);
    }
  }
}
