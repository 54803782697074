@mixin ion-item-default-style {
  --border-width: 0;
  --background: transparent;
  --padding-start: 0;
  --inner-padding-end: 0;
  --border-color: transparent;
  --inner-border-width: 0;
  --highlight-color-focused: transparent;
  --highlight-background: transparent !important;
}

@mixin ion-input-default-style {

  ion-label {
    color: var(--om-grey-secondary) !important;
    margin-bottom: 6px !important;
  }

  ion-input, ion-select, ion-textarea {
    --padding-start: 12px !important;
    --placeholder-color: var(--om-grey-tertiary) !important;
    color: var(--om-grey-primary) !important; // TODO, REVER Com o bel campo preenchido..
    font-size: 14px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid var(--om-grey-dividers); // TODO, testar no dispositivo...
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);

    &.select-expanded,
    &.has-focus {
      border: 1px solid var(--om-module-primary-color);
      background-color: white;
    }
  }
}

@mixin default-custom-modal-style {
  --backdrop-opacity: .4 !important;

  &::part(content) {
    --box-shadow: transparent;
    --border-radius: 16px !important;
    border-radius: 16px !important;
    overflow: hidden !important;
  }

  .modal-wrapper {
    border-radius: 16px !important;
    overflow: hidden !important;
  }
}

@mixin default-modal-content-style {

  ion-header {
    padding: var(--app-custom-modal-default-padding);
    border-bottom: 1px solid var(--om-grey-dividers);
    display: flex;

    &:after {
      background-image: unset;
    }

    ion-title {
      font-size: 18px;
      font-weight: 700;
      width: fit-content !important;
      padding: 0 !important;
      text-align: initial;
      position: relative;
    }
  }

  ion-content {
    --padding-top: var(--app-custom-modal-default-padding);
    --padding-bottom: var(--app-custom-modal-default-padding);
    --padding-start: var(--app-custom-modal-default-padding);
    --padding-end: var(--app-custom-modal-default-padding);
  }

  ion-footer {
    padding: var(--app-custom-modal-default-padding);
  }
}

.app-oncomax-custom-alert {
  --max-width: 26rem;
  --max-height: 16rem;
  --backdrop-opacity: 0.3 !important;

  &::part(content) {
    border-radius: 2px;
    overflow: hidden !important;
    --box-shadow: transparent;
  }

  .modal-wrapper {
    border-radius: 16px !important;
    overflow: hidden !important;
  }

  @include default-custom-modal-style;

  .app-oncomax-custom-modal-content {

    @include default-modal-content-style;
  }
}

.app-oncomax-custom-modal {
  @include default-custom-modal-style;

  .app-oncomax-custom-modal-content {

    @include default-modal-content-style;
  }
}
